.navigation-container {
  background-color: transparent;
}

.navigation-container > svg {
  font-size: 3rem;
  padding-top: 1rem;
  height: 100%;
  cursor: pointer;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  gap: 2rem;
}

.navigation-container > a {
  color: white !important;
}
