* {
  color: white;
}

.about-container {
  min-height: 100vh;
  padding: 5rem;
  font-family: Poppins, sans-serif;
}

.about-description {
  letter-spacing: 1px;
  text-align: justify;
  line-height: 1.8rem;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.about-skills-container {
  width: 100%;
  margin-top: 5rem;
  min-height: 20rem;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
}

.about-skill-item,
.about-skill-item * {
  background-color: #ededed;
}

.about-skill-item {
  min-height: 250px;
  width: 225px;
  box-shadow: 0px 0px 15px lightgray;
  border: 1px solid #ededed;
  border-radius: 25px;
  overflow: hidden;
}

.about-skill-item-content {
  margin-top: 4rem;
  width: 100%;
  text-align: center;
  transition: transform 0.75s ease;
}

.skill-title {
  color: rgb(100 100 100);
  font-size: 1.5rem;
  font-weight: 600;
}

.about-skill-item .skill-icon * {
  color: rgb(100 100 100);
  font-size: 4rem;
  margin-top: 1.5rem;
}

.about-skill-item-content:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 960px) {
  .about-skill-item {
    min-height: 225px;
    width: 200px;
  }
}

.tec-container {
  padding: 5rem;
  min-height: 100vh;
  min-height: 100vh;
}

.tec-list {
  width: 100%;
  font-size: 8rem;
  margin-top: 5rem;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 960px) {
  .tec-list {
    font-size: 5rem;
    gap: 2rem;
  }
}

.tec-list * {
  color: #ededed;
}

.tec-list > svg:hover {
  transform: scale(1.2);
}

.tec-list > svg {
  box-shadow: 0px 0px 15px lightgray;
  border-radius: 2rem;
  padding: 1rem;
  transition: transform 0.7s;
}
