.header {
  display: 'flex';
  align-items: right;
  background-color: black;
  opacity: '0.5';
  max-height: '100%';
  overflow: hidden;
  justify-content: space-between;
  font-size: 2.2rem;
  background-color: black;
}

.logo-container {
  height: 100%;
  opacity: 0.2;
}

.logo {
  max-height: 100%;
  opacity: 0.2;
}

.anchor-container {
  font-size: 20px;
  font-family: Poppins, sans-serif;
}

.anchor {
  margin: 10px;
  color: rgb(59, 59, 59);
  font-weight: 700;
  transition: font-size 0.5s;
}

.anchor:hover {
  color: rgb(59, 59, 59);
  opacity: 0.7;
  font-size: 24px;
}

h1 {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 2rem;
}

h2 {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
}

h4 {
  color: lightgray;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content > div {
  max-width: 1500px;
  width: 100%;
}

.footer {
  background-color: black;
  display: inline-flex;
  justify-content: center;
  gap: 0.5rem;
}

.header {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  background-color: transparent;
}

.navigation-container > svg {
  background-color: transparent !important;
}
